<template>
  <div class="auth-page">
    <h1 class="text__headline-h3">
      {{ $t('pages.login.login') }}
    </h1>
    <Input
      v-for="input in inputs"
      :key="input.name"
      v-model="input.value"
      :error-message="input.error"
      :label="input.label"
      :name="input.name"
      :placeholder="input.placeholder"
      :type="input.type"
    />
    <!--        <NuxtLink -->
    <!--          class="text__link text__paragraph-mid" -->
    <!--          to="/password-recovery/request" -->
    <!--        > -->
    <!--          {{ $t('pages.login.passwordReset') }} -->
    <!--        </NuxtLink> -->
    <Button
      button-text="pages.login.signIn"
      class="mt-8"
      icon="fa-solid:check-circle"
      :loading="SignInLoading"
      type="submit"
      @click="signIn"
    />
    <div>
      <span class="text__paragraph pr-2">
        {{ $t('pages.login.noAccount') }}
      </span>
      <NuxtLink
        class="text__link text-primary"
        to="/register"
      >
        {{ $t('pages.login.signUp') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { AuthenticateDocument } from '~/graphql/generated/graphql';
import { APOLLO_TOKEN } from '~/constants';

const { onLogin } = useApollo();

definePageMeta({
  layout: 'auth'
});

const { t } = useI18n();

const tokenCookie = useCookie(APOLLO_TOKEN);

const validationSchema = Yup.object().shape({
  login: Yup.string().email(t('errors.email')).trim().required(t('errors.required')),
  password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('errors.passwordRules')).trim().required(t('errors.required'))
});

const { handleSubmit } = useForm({
  validationSchema,
  initialValues: {
    login: '',
    password: ''
  }
});

const { value: loginValue, errorMessage: loginError } = useField(() => 'login');
const { value: passwordValue, errorMessage: passwordError } = useField(() => 'password');

const { mutate: SignIn, loading: SignInLoading } = useMutation(AuthenticateDocument);

const signIn = handleSubmit(async (values) => {
  tokenCookie.value = null;
  const signInResult = await SignIn({
    username: values.login,
    password: values.password
  });
  const token = signInResult?.data?.authenticate;
  if (token) {
    await onLogin(token);
    navigateTo('/');
  }
});

const inputs = ref([
  {
    label: t('pages.login.login'),
    name: 'username',
    placeholder: t('pages.login.login'),
    type: 'email',
    value: loginValue,
    error: loginError
  },
  {
    label: t('pages.login.password'),
    name: 'password',
    placeholder: t('pages.login.password'),
    type: 'password',
    value: passwordValue,
    error: passwordError
  }
]);
</script>
